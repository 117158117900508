import React, { Component, createRef } from 'react';
import { Navigate } from "react-router-dom";

import DataTable from 'components/blocks/data_table/DataTable';
import WdForm from 'components/wd/WdForm';

import IconButton from '@mui/material/IconButton';
import Btn from 'components/Btn';

import Main from 'components/Main';

import Api from 'api/Api';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import LayoutHelper from 'helpers/LayoutHelper';

import Ratings from 'components/Ratings';

class JobsListing extends Component { 
    constructor(props) {
        super();
        this.state = {

            account_token: '',
            error_message: '',
            success_message: '',

            loading: false,

            user: false,

            row: false,
            active_row: false,

            row_data: false,

            reloading: false,
            do_reload: false,

            anchorEl: null,

            redirect: '',

            form_popup: false,
            
            states: [],

            categories: [
                {key: 'personal_trainer', value: 'Personal Trainer'},
                {key: 'nutritionist', value: 'Dietician Nutritionist'},
                {key: 'physiotherapy', value: 'Physiotherapist'},
                {key: 'yoga', value: 'Yoga Teacher'},
                {key: 'management', value: 'Management'},
            ]
        }

        this.filter = createRef();
    }

    componentDidMount = () => {

        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let user = localStorage.getItem(process.env.REACT_APP_ACCOUNT_USER);
        
        if(account_token && user){

            this.setState({account_token: account_token}, () => {

                this.init(account_token)
            });
        }else{

            // window.location = Api.url + 'logout';
        }
    }

    render () {

        if(this.state.redirect !== ''){

            return <Navigate to={`/${this.state.redirect}`} />
        }

        return (

            <Main
                active_page="jobs"

                error_message={this.state.error_message}
                success_message={this.state.success_message}

                page="jobs_list"
                title={"Jobs"}
            >

                {(this.state.account_token) &&

                    <>

                        <DataTable
                            index="professionals_reviews"
                            label="Reviews"

                            active_row={this.state.active_row}

                            loading={this.state.reloading}
                            
                            do_reload={this.state.do_reload}
                            relaodDone={() => {

                                this.setState({do_reload: false});
                            }}

                            // filter_params={this.state.filter_params}

                            columns={[
                                {name: 'Logo', column: 'employer_logo', sortable: false, hide_search: true, width: 60, renderer: (row) => {

                                    return (
                                        <div style={{width: 50, height: 50, backgroundImage: `url(${row.employer_logo_url})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: 100}}></div>
                                    )
                                }},
                                {name: 'Job Code', column: 'job_code', sortable: true},
                                {name: 'Job Title', column: 'job_title', sortable: true},
                                {name: 'Category', column: 'category', search_type: 'match', search_input: 'dropdown', search_data: this.state.categories},
                                {name: 'Employer Name', column: 'employer_name', sortable: true},
                                {name: 'Employer Contact', column: 'employer_contact', sortable: true},
                                {name: 'Employer Email', column: 'employer_email', sortable: true},
                                {name: 'State', column: 'state', search_type: 'match', search_input: 'dropdown', search_data: this.state.states},
                                {name: 'City', column: 'city', sortable: true},
                                {name: 'Area', column: 'area', sortable: true},
                                {name: 'Job Date', column: 'start_date', sortable: true, width: 100, renderer: (row) => {

                                    return <span>{row.start_date_formatted}</span>
                                }},
                                {name: 'Status', column: 'status', db_column: 'jobs.status', search_type: 'match', search_input: 'dropdown', search_data: [{key: '0', value: 'Not Verified'}, {key: '1', value: 'Active'}, {key: '2', value: 'Inactive'}], chip_colors: {'0': 'primary', '1': 'success', '2': 'error'}},
                            ]}

                            row_actions={(row, row_index) => {

                                return (

                                    <div className="hoverable-action">
                                        <div className="align-start">

                                            <IconButton size="small" color="primary" onClick={(e) => {

                                                this.setState({row: row, active_row: row_index}, () => {

                                                    this.setState({form_popup: true})
                                                })
                                            }}>
                                                <Edit style={{fontSize: 18}} />
                                            </IconButton>

                                            <Btn icon={true} size="small" color="primary" confirm_message="Do you really want to delete this job?" confirm={true} onClick={(e) => {

                                                this.removeJob(row)
                                            }}>
                                                <Delete style={{fontSize: 18}} className='c-red' />
                                            </Btn>
                                        </div>
                                    </div>
                                )
                            }}

                            per_page={10}

                            default_sort_by="added_on"
                            sort_by_dir="desc"

                            api_url="backend/jobs/collection"

                            account_token={this.state.account_token}
                            
                            row_id="row_id"
                            view_url="employees/leave_application/listing"
                        />

                        <WdForm
                            
                            title='Job'
                            back_label="Cancel"

                            drawer={true}
                            open={this.state.form_popup}

                            size="medium"

                            submit_url='backend/jobs/save'
                            data_url='backend/jobs/load'

                            submit_continue={true}

                            onSubmit={(result) => {

                                this.setState({form_popup: false, do_reload: true, active_row: false, row_id: '', row: false})
                                LayoutHelper.addSuccessMessage(this, result.message)
                            }}
                            onBack={() => {

                                this.setState({form_popup: false, active_row: false, row_id: '', row: false})
                            }}

                            onDataLoad={(row_data, data) => {

                                this.setState({row_data: row_data})
                            }}
                        
                            row_id={this.state.row.row_id}
                            id="row_id"
                            title_field="job_title"
                            updated_on="updated_on_formatted"
                                                    
                            fields={{
                                rows: [
                                    {
                                        fields: [
                                            {key: 'status', type: 'radio', name: 'status', label: 'Status', validations: ['r'], span: 12, options: [{key: '1', value: 'Active'}, {key: '2', value: 'Inactive'}]},
                                        ]
                                    },
                                    {
                                        fields: [
                                            {key: 'job_html', type: 'html', span: 12, html: (
                                                <div>
                                                    <table style={{width: '100%'}} cellPadding={10}>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Job Title</strong>
                                                                    <span className='mt-5'>{this.state.row_data.job_title}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Category</strong>
                                                                    <span className='mt-5'>{this.state.row_data.category_label}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Employer Name</strong>
                                                                    <span className='mt-5'>{this.state.row_data.employer_name}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Employer Contact</strong>
                                                                    <span className='mt-5'>{this.state.row_data.employer_contact}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Employer Email</strong>
                                                                    <span className='mt-5'>{this.state.row_data.employer_email}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Job Start Date</strong>
                                                                    <span className='mt-5'>{this.state.row_data.start_date_formatted}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Job Description</strong>

                                                                    <div
                                                                        className={`content`}
                                                                        dangerouslySetInnerHTML={{__html: this.state.row_data.job_description}}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}>
                                                                <div className='vertical fs-13'>
                                                                    <strong>Roles & Responsibility</strong>

                                                                    <div
                                                                        className={`content`}
                                                                        dangerouslySetInnerHTML={{__html: this.state.row_data.roles_responsibility}}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )}
                                        ]
                                    }
                                ]
                            }}
                        />
                    </>
                }
            </Main>
        )
    }

    init = (account_token) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', account_token);

        Api.post('backend/jobs/init', formData, function(data){

            if(data.status){

                that.setState({
                    states: data.states
                });

            }else{

                // LayoutHelper.addErrorMessage(that, data.message);
            }
        });
    }

    removeJob = (row) => {

        var formData = new FormData();
        var that = this;

        formData.append('account_token', this.state.account_token);
        formData.append('row_id', row.row_id);

        this.setState({reloading: true})

        Api.post('backend/jobs/entity/remove', formData, function(data){

            that.setState({
                reloading: false,
                do_reload: true
            });

            LayoutHelper.addSuccessMessage(that, data.message)
        });
    }
}

export default JobsListing;
